<template lang="pug">
.app(:data-step="step")
  .container.py-3
    .step.step-terms(v-if="step === 1")
      terms
      div(v-waypoint='{ active: true, callback: onWaypoint, options: intersectionOptions }')
    .step.step-register(v-if="step === 2")
      h2.title.text-center.text-white.mx-n3.mt-n3.px-3.py-4.mb-4 ลงทะเบียน
      form.d-grid.gap-4.mb-4(@submit.prevent="onSubmit", novalidate)
        .form-group
          label.form-label.fs-5.fw-bold.mb-3(for="name") ชื่อ - นามสกุล
            small.fs-12.fw-normal.opacity-50.d-block (ใช้เพื่อคัดเลือก และประกาศรางวัล)
          input#name.form-control.form-control-lg(
            type="text",
            v-model="form.Name",
            required
          )
          .invalid-feedback กรุณากรอก ชื่อ - นามสกุล

        .form-group
          label.form-label.fs-5.fw-bold.mb-3(for="phone") เบอร์ติดต่อ
            small.fs-12.fw-normal.opacity-50.d-block (ใช้เพื่อคัดเลือก และประกาศรางวัล)
          input#phone.form-control.form-control-lg(
            type="tel",
            v-model="form.Phone",
            minlength="9",
            maxlength="10",
            required
          )
          .invalid-feedback กรุณากรอก เบอร์ติดต่อ

        .form-group
          label.form-label.fs-5.fw-bold.mb-3(for="address") ที่อยู่ในการจัดส่ง
            small.fs-12.fw-normal.opacity-50.d-block (ใช้เพื่อคัดเลือก และประกาศรางวัล)

          textarea#address.form-control.form-control-lg(
            v-model="form.Address",
            rows="3",
            required
          )
          .invalid-feedback กรุณากรอก ที่อยู่ในการจัดส่ง


        .form-group
          label.form-label.fs-5.fw-bold.mb-3.d-block คุณรู้หรือไม่ว่า ! 
            span.text-primary.fs-2 6 
            span.text-primary เมนู
            | ในแอปพลิเคชัน GHB ALL มีอะไรบ้าง?
          .d-grid.gap-3
            label(
              v-for="({ value, label }, index) in answers",
              :key="value",
              :for="`answer-${value}`"
            )
              .choice-item.d-flex.align-items-center.border.rounded-3.py-2.px-3
                input.d-none(
                  v-model="form.Answer",
                  type="radio",
                  :value="value",
                  :id="`answer-${value}`"
                  required
                ) 
                svg(
                  width="16",
                  height="16",
                  viewbox="0 0 16 16",
                  fill="#d8dbdf",
                  xmlns="http://www.w3.org/2000/svg"
                ).me-3.flex-shrink-0
                  path(
                    d="M15.75 8C15.75 12.2802 12.2802 15.75 8 15.75C3.71978 15.75 0.25 12.2802 0.25 8C0.25 3.71978 3.71978 0.25 8 0.25C12.2802 0.25 15.75 3.71978 15.75 8ZM7.10356 12.1036L12.8536 6.35356C13.0488 6.15831 13.0488 5.84172 12.8536 5.64647L12.1465 4.93937C11.9512 4.74409 11.6346 4.74409 11.4393 4.93937L6.75 9.62869L4.56066 7.43934C4.36541 7.24409 4.04881 7.24409 3.85353 7.43934L3.14644 8.14644C2.95119 8.34169 2.95119 8.65828 3.14644 8.85353L6.39644 12.1035C6.59172 12.2988 6.90828 12.2988 7.10356 12.1036Z"
                  )
                span.d-block.lh-sm.py-1(v-html="label")

          .invalid-feedback(
            :class="{ 'd-block': !form.Answer && submited }"
          ) กรุณาเลือก คุณรู้หรือไม่ว่า 6 เมนูในแอปพลิเคชัน GHB ALL มีอะไรบ้าง?

        .form-group
          label.form-label.fs-5.fw-bold.mb-3(for="Feedback") ท่านอยากให้มีเมนูหรือฟีเจอร์อะไรเพิ่มเติมอีกบ้าง?
          textarea#Feedback.form-control.form-control-lg(
            v-model="form.Feedback",
            rows="3",
            required
            placeholder="โปรดระบุ?"
          )
          .invalid-feedback กรุณาระบุ ท่านอยากให้มีเมนูหรือฟีเจอร์อะไรเพิ่มเติมอีกบ้าง?


        p.text-center.opacity-50
          strong หมายเหตุ
          |
          | : กรุณากรอกข้อมูลให้ถูกต้องครบถ้วน <br> เพื่อประโยชน์ในการร่วมกิจกรรมของท่าน

    .step.step-success.text-center.d-flex.align-items-center(v-if="step === 3")
      .w-100
        happy-student.mb-5
        h2.h1.text-primary.mb-4 ขอบคุณที่เข้าร่วมกิจกรรม
        p.text-center.mb-3 เจ้าหน้าที่จะทำการติดต่อท่านไปตามข้อมูลที่ท่าน <br> ได้ทำการลงทะเบียนหากท่านได้รับรางวัล
        h4 ประกาศผลกิจกรรม
        p วันที่ 5 สิงหาคม 2565 <br> เวลา 18.00 น

    .step.step-success.text-center.d-flex.align-items-center(v-if="step === 4")
      .w-100
        finish.mb-5
        h2.h1.text-primary.mb-4 สิ้นสุดการเข้าร่วมกิจกรรม
        p.text-center.mb-3 โปรดติดตามกิจกรรมจากทางธนาคาร <br> ในครั้งถัดไปเร็ว ๆ นี้

    .action.position-fixed.start-0.end-0.bottom-0.p-3
      template(v-if="step === 1")
        .pb-2.text-center.fs-14(for="acceptTerm") ข้าพเจ้ายอมรับในข้อตกลงเเละเงื่อนไข
        .d-flex.gap-2

          button.btn.btn-lg.btn-secondary.rounded-pill.w-50(
            :disabled="!hasScrolledToBottom || isLoading"
            :class="{ 'opacity-50' : !hasScrolledToBottom }"
            @click="success"
          ) ไม่ยอมรับ

          button.btn.btn-lg.btn-block.rounded-pill.text-white.w-50.btn-primary(
            type="button",
            :disabled="!hasScrolledToBottom || isLoading",
            @click="checkRegister",
            :class="{ 'opacity-50' : !hasScrolledToBottom }"
          ) 
            span.spinner-border.spinner-border-sm(
              v-if="isLoading",
              role="status",
              aria-hidden="true"
            )
            span(v-else) ยอมรับ
        

      template(v-if="step === 2")
        button.btn.btn-primary.btn-lg.btn-block.rounded-pill.text-white.w-100(
          type="submit",
          :disabled="isLoading",
          @click="onSubmit"
        ) 
          span.spinner-border.spinner-border-sm(
            v-if="isLoading",
            role="status",
            aria-hidden="true"
          )
          span(v-else) ส่งคำตอบ

      template(v-if="step === 3 || step === 4")
        button.btn.btn-primary.btn-lg.btn-block.rounded-pill.text-white.w-100(
          type="button",
          @click="success"
        )
          span(v-if="step === 4") ปิดหน้าต่าง
          span(v-else) เสร็จสิ้น
</template>

<script>
import axios from "axios";
import "./assets/bootstrap.scss";
import liff from "@line/liff";
import Terms from "@/components/Terms.vue";
import HappyStudent from "@/components/HappyStudent.vue";
import DonePana from "@/components/DonePana.vue";

import Close from "@/components/Close.vue";
import Done from "@/components/Done.vue";
import Finish from "@/components/Finish.vue";

const { GoogleSpreadsheet } = require("google-spreadsheet");
const creds = require("@/client_secret.json");

export default {
  components: { Terms, HappyStudent, DonePana, Done, Close, Finish },
  data() {
    return {
      sheetId: "1pGvjSmxaSrUSwZxLyyyJ0oNX1r3J-gbA7dMSuUiwIaM",
      form: {},
      step: 4,
      profile: {},
      isLoading: false,
      submited: false,
      rows: [],
      hasScrolledToBottom: false,
      answers: [
        {
          label: "พร้อมเพย์, สินเชื่อ, เงินกู้, โอนเงิน, ใบเสร็จ, เปิดบัญชี",
          value: "1",
        },
        {
          label: `สินเชื่อ, เงินกู้, เงินฝาก, โอนเงิน, ใบเสร็จ, ชำระเงินกู้/ค่าบริการอื่นๆ`,
          value: "2",
        },
        {
          label: `พร้อมเพย์, สินเชื่อ, เงินฝาก, โอนเงิน, ชำระเงินกู้/ค่าบริการอื่นๆ, ใบเสร็จ`,
          value: "3",
        },
        {
          label: `เปิดบัญชี, พร้อมเพย์, สินเชื่อ, โอนเงิน, เงินฝาก, ถอนเงิน`,
          value: "4",
        },
      ],
      intersectionOptions: {
        root: null,
        rootMargin: "0px 0px 0px 0px",
        threshold: [0, 1],
      },
    };
  },

  watch: {
    step() {
      window.scrollTo(0, 0);
    },
  },

  // created() {
  //   window.addEventListener("scroll", this.handleScroll);
  // },

  // destroyed() {
  //   window.removeEventListener("scroll", this.handleScroll);
  // },

  // mounted() {
  //   liff
  //     .init({ liffId: "1655692268-o1d6w9aw" })
  //     .then(() => {
  //       if (!liff.isLoggedIn()) {
  //         liff.login();
  //       } else {
  //         liff
  //           .getProfile()
  //           .then((profile) => {
  //             this.profile = profile;
  //           })
  //           .catch((err) => {
  //             console.log("error", err);
  //           });
  //       }
  //     })
  //     .catch((error) => {
  //       console.log(error);
  //     });
  // },

  methods: {
    async onSubmit() {
      this.submited = true;
      const form = document.querySelector("form");
      if (!form.checkValidity()) {
        form.classList.add("was-validated");
        const el = form.querySelector("*:invalid");
        if (el) el.scrollIntoView();
      } else {
        this.isLoading = true;
        this.form.UserId = this.profile.userId;
        this.form.Time = new Date();
        this.form.AcceptTerm = this.form.AcceptTerm ? "ยอมรับ" : "ไม่ยอมรับ";

        const doc = new GoogleSpreadsheet(this.sheetId);
        await doc.useServiceAccountAuth(creds);
        await doc.loadInfo();
        const sheet = doc.sheetsByIndex[0];
        await sheet.addRow(this.form);

        this.isLoading = false;
        this.step = 3;
      }
    },

    onSubmit2() {
      this.submited = true;
      const form = document.querySelector("form");
      if (!form.checkValidity()) {
        form.classList.add("was-validated");
        const el = form.querySelector("*:invalid");
        if (el) el.scrollIntoView();
      } else {
        this.isLoading = true;
        this.form.UserId = this.profile.userId;
        this.form.AcceptTerm = this.form.AcceptTerm ? "ยอมรับ" : "ไม่ยอมรับ";
        axios
          .post(
            "https://ghbank-online-activity.digitalsetup.co/api/v1/register_06_2022",
            this.form
          )
          .then(() => {
            this.isLoading = false;
            this.step = 3;
          })
          .catch((error) => {
            console.log(error);
            this.isLoading = false;
          });
      }
    },

    success() {
      liff.closeWindow();
    },

    handleScroll() {
      var totalPageHeight = document.body.scrollHeight;
      var scrollPoint = window.scrollY + window.innerHeight;

      if (scrollPoint >= totalPageHeight) {
        this.hasScrolledToBottom = true;
      }
    },

    onWaypoint({ going }) {
      if (going === this.$waypointMap.GOING_IN) {
        this.hasScrolledToBottom = true;
      }
    },

    checkRegister() {
      this.step = 2;
      this.form.AcceptTerm = 1;
      // this.isLoading = true;
      // const doc = new GoogleSpreadsheet(this.sheetId);
      // await doc.useServiceAccountAuth(creds);
      // await doc.loadInfo();
      // const sheet = doc.sheetsByIndex[0];
      // const rows = await sheet.getRows();
      // this.isLoading = false;
      // const data = rows.filter((el) => {
      //   return el.UserId === this.profile.userId;
      // });

      // if (data.length) {
      //   this.step = 4;
      // } else {
      //   this.step = 2;
      //   this.form.AcceptTerm = 1;
      // }
    },
  },
};
</script>

<style lang="scss" scoped>
.title {
  font-size: 26px;
  background: url(assets/images/title-bg.jpg);
  background-size: cover;
}
.house-item {
  &:active {
    opacity: 0.5;
  }

  input:checked + svg {
    fill: var(--bs-primary);
  }
}

.choice-item {
  &:active {
    opacity: 0.5;
  }

  input:checked + svg {
    fill: var(--bs-primary);
  }
}

.step-success {
  min-height: calc(100vh - 120px);
}
</style>

<style lang="scss">
.app {
  overflow: hidden;
  padding-bottom: 80px;
  background: #fff;
  min-height: 100vh;

  &[data-step="1"] {
    padding-bottom: 115px;
  }

  &[data-step="3"] {
    background: url(assets/images/bg.jpg);
    background-size: cover;
  }
}
.action {
  background-color: rgba(255, 255, 255, 0.6);
  backdrop-filter: saturate(200%) blur(20px);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);

  @media (min-width: 576px) {
    width: 575px;
    left: 50% !important;
    transform: translateX(-50%);
  }
}
body {
  max-width: 575px;
  margin: auto;

  @media (min-width: 576px) {
    background: #f8f8f8;
  }
}

.house-item {
  svg.icon {
    width: 100px;
    height: auto;
    fill: #ff850d;
  }
}
</style>